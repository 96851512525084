import { getApiClient } from './AxiosService'

async function getProductsService() {
  try {
    const result = await getApiClient().get('proizvodi')
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    } else {
      return {
        isOk: false,
        message: '',
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getCategoriesService() {
  try {
    const result = await getApiClient().get('kategorije')
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    } else {
      return {
        isOk: false,
        message: '',
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: '',
    }
  }
}

async function addProductToFavouriteService(data) {
  try {
    const result = await getApiClient(data.accessToken).post('favourites', data)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function removeProductFromFavouriteService(data) {
  try {
    const result = await getApiClient(data.accessToken).post(
      'favourites-delete',
      data
    )
    if (result.status === 200 && result.data && result.data.success) {
      return {
        isOk: true,
      }
    }
  } catch (error) {
    // console.log('remove product service error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getNovaPonudaService() {
  try {
    const result = await getApiClient().get(`nova-ponuda`)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    // console.log('home error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getPopularniProizvodiService() {
  try {
    const result = await getApiClient().get(`popularni-proizvodi`)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    // console.log('home error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getDisabledProductsService(disabledFavoritiIds) {
  const data = {
    ids: disabledFavoritiIds,
  }
  try {
    const result = await getApiClient().post(`get-disabled-products`, data)
    console.log('service result: ', result)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    console.log('disabled error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

export {
  getProductsService,
  getCategoriesService,
  addProductToFavouriteService,
  removeProductFromFavouriteService,
  getNovaPonudaService,
  getPopularniProizvodiService,
  getDisabledProductsService,
}
