import api from "../constants/Api";
import axios from "axios";

const getApiClient = (token = null, customHeaders = {}, timeout = 10000) => {
  let headers = Object.assign(
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      'Platform': 'web'
    },
    customHeaders
  );
  if (token) {
    headers.Authorization = token;
  }

  return axios.create({
    baseURL: api.BASE_API_URL,
    headers,
    timeout,
  });
};

export { getApiClient };
