import * as actionTypes from './ActionTypes'
import {
  getProductsService,
  getCategoriesService,
  getNovaPonudaService,
  getPopularniProizvodiService,
} from '../../services/Products'

export const getProducts = () => async (dispatch) => {
  const result = await getProductsService()
  if (result.isOk) {
    dispatch(getProductsSuccess(result.data))
  }
}

export const getProductsSuccess = (products) => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    payload: {
      products,
    },
  }
}

export const getCategories = () => async (dispatch) => {
  const result = await getCategoriesService()
  if (result.isOk) {
    dispatch(getCategoriesSuccess(result.data))
  }
}

export const getCategoriesSuccess = (categories) => {
  return {
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    payload: {
      categories,
    },
  }
}

export const updateActiveCategoryIndex = (categoryIndex) => {
  return {
    type: actionTypes.UPDATE_ACTIVE_CATEGORY_INDEX,
    payload: {
      categoryIndex,
    },
  }
}

export const addProductToCart = (product) => {
  return {
    type: actionTypes.ADD_PRODUCT_TO_CART,
    payload: {
      product,
    },
  }
}

export const removeProduct = (productId) => {
  return {
    type: actionTypes.REMOVE_PRODUCT,
    payload: {
      productId,
    },
  }
}

export const decreaseQuantity = (productId) => {
  return {
    type: actionTypes.DECREASE_QUANTITY,
    payload: {
      productId,
    },
  }
}

export const increaseQuantity = (productId) => {
  return {
    type: actionTypes.INCREASE_QUANTITY,
    payload: {
      productId,
    },
  }
}

export const updateQuantity = (data) => {
  return {
    type: actionTypes.UPDATE_QUANTITY,
    payload: {
      productId: data.productId,
      quantity: data.quantity,
    },
  }
}

export const removeProductsFromCart = () => {
  return {
    type: actionTypes.REMOVE_PRODUCTS_FROM_CART,
  }
}

export const removeUnavailableProducts = (data) => {
  const products = [...data.products]
  let productsInCart = [...data.productsInCart]

  let newProductsInCart = []

  productsInCart.forEach((product) => {
    let exists = products.find((x) => x.proizvodID === product.proizvodID)
    if (exists) {
      newProductsInCart.push(product)
    }
  })

  return {
    type: actionTypes.REMOVE_UNAVAILABLE_PRODUCTS,
    payload: { newProductsInCart },
  }
}

export const addProductToFavourite = (productId) => {
  return {
    type: actionTypes.ADD_PRODUCT_TO_FAVOURITE,
    payload: { productId },
  }
}

export const removeProductFromFavourite = (productId) => {
  return {
    type: actionTypes.REMOVE_PRODUCT_FROM_FAVOURITE,
    payload: { productId },
  }
}

// called after log in (No need to call it after registration because fav products are already in local storage)
export const setFavourites = (favourites) => {
  let favouriteProducts = []
  favourites.forEach((x) => favouriteProducts.push(x.proizvodID))
  return {
    type: actionTypes.SET_FAVOURITES,
    payload: { favouriteProducts },
  }
}

export const clearFavouriteProducts = () => {
  return {
    type: actionTypes.CLEAR_FAVOURITE_PRODUCTS,
  }
}

export const getNovaPonuda = () => async (dispatch) => {
  const result = await getNovaPonudaService()
  if (result.isOk) {
    dispatch(getNovaPonudaSuccess(result.data))
  }
}

export const getNovaPonudaSuccess = (data) => {
  return {
    type: actionTypes.GET_NOVA_PONUDA_SUCCESS,
    payload: {
      data,
    },
  }
}

export const getPopularniProizvodi = () => async (dispatch) => {
  const result = await getPopularniProizvodiService()
  if (result.isOk) dispatch(getPopularniProizvodiSuccess(result.data))
}

export const getPopularniProizvodiSuccess = (data) => {
  return {
    type: actionTypes.GET_POPULARNI_PROIZVODI_SUCCESS,
    payload: {
      data,
    },
  }
}
