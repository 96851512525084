import * as actionTypes from "../Actions/ActionTypes"

const initialState = {
  userData: {},
  loginError: null,
  registerError: null,
  updateUserError: null,
}

const loginSuccess = (state, action) => {
  let userData = action.payload.userData
  return {
    ...state,
    userData,
    loginError: null,
  }
}

const setLoginError = (state, action) => {
  let { loginError } = action.payload
  return {
    ...state,
    loginError,
  }
}

const registerSuccess = (state, action) => {
  let { userData } = action.payload
  return {
    ...state,
    userData,
    registerError: null,
  }
}

const setRegisterError = (state, action) => {
  let { registerError } = action.payload
  return {
    ...state,
    registerError,
  }
}

const logout = (state, action) => {
  return {
    ...state,
    userData: {},
  }
}

const updateUserSuccess = (state, action) => {
  let userData = action.payload.userData
  return {
    ...state,
    userData,
    updateUserError: null,
  }
}

const setUserUpdateError = (state, action) => {
  const error = action.payload.error
  return {
    ...state,
    updateUserError: error,
  }
}

const getUserSuccess = (state, action) => {
  const userData = action.payload.userData
  return {
    ...state,
    userData
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action)
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action)
    case actionTypes.SET_USER_UPDATE_ERROR:
      return setUserUpdateError(state, action)
    case actionTypes.LOGOUT:
      return logout(state, action)
    case actionTypes.SET_LOGIN_ERROR:
      return setLoginError(state, action)
    case actionTypes.SET_REGISTER_ERROR:
      return setRegisterError(state, action)
    case actionTypes.REGISTER_SUCCESS:
      return registerSuccess(state, action)
    case actionTypes.GET_USER_SUCCESS: 
      return getUserSuccess(state, action)
    default:
      return state
  }
}

export default reducer
