import * as actionTypes from '../Actions/ActionTypes'

const initialState = {
  deliveryPrice: {},
  minimumOrder: {}
}

const getDeliveryPriceSuccess = (state, action) => {
  let data = action.payload.data
  return {
    ...state,
    deliveryPrice: data,
  }
}

const getMinimumOrderPriceSuccess = (state, action) => {
  let data = action.payload.data
  return {
    ...state,
    minimumOrder: data
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DELIVERY_PRICE_SUCCESS:
      return getDeliveryPriceSuccess(state, action)
    case actionTypes.GET_MINIMUM_ORDER_PRICE_SUCCESS:
      return getMinimumOrderPriceSuccess(state, action)
    default:
      return state
  }
}

export default reducer
