import { getApiClient } from './AxiosService'

async function getUserService(token) {
  try {
    const response = await getApiClient(token).post('korisnik')
  if (response.status === 200) {
    return {
      isOk: true,
      data: response.data.data,
    }
  } else {
    return {
      isOk: false,
    }
  }
  } catch (error) {
    return {
      isOk: false,
    }
  }
}

async function loginService(username, password) {
  const data = { korisnicko_ime: username, lozinka: password }
  try {
    const result = await getApiClient().post('korisnik/login', data)
    if (result.status === 200 && result.data && result.data.success) {
      return {
        isOk: true,
        data: result.data.data,
        token: result.data.token,
        favourites: result.data.favourites,
      }
    } else if (result.status === 401 && result.data.code === 401) {
      return {
        isOk: false,
        message: result.data.error,
      }
    } else {
      return {
        isOk: false,
        message: '',
      }
    }
  } catch (error) {
    let response = {
      isOk: false,
      mesage: error.message,
    }
    if (error?.response?.status === 401) {
      response.message = error.response.data.error
    }
    return response
  }
}

async function registerService(data) {
  try {
    const result = await getApiClient().post('korisnik/register', data)
    if (result.status === 201 && result.data && result.data.success) {
      return {
        isOk: true,
        data: result.data.user,
        token: result.data.token,
      }
    }

    // Duplicte user
    if (result.status === 200 && result.data.errorInfo[1] === 1062) {
      return {
        isOk: false,
        message: 'Email je već u upotrebi.',
      }
    }
  } catch (error) {
    // console.log('register service error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function updateUserService(data) {
  try {
    const result = await getApiClient(data.accessToken).put('korisnik', data)
    if (result.status === 200 && result.data.success) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
    // Duplicte user
    else if (result.status === 200 && result.data.errorInfo[1] === 1062) {
      return {
        isOk: false,
        message: 'Email je već u upotrebi.',
      }
    } else {
      return {
        isOk: false,
        message: 'Desila se greška!',
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function verifyEmailService(data) {
  try {
    const result = await getApiClient().post('verify-email', data)
    if (result.data && result.data.success) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function sendEmailForForgotPasswordService(data) {
  try {
    const result = await getApiClient().post('forgot-password', data)
    if (result.data && result.data.success) {
      return {
        isOk: true,
      }
    } else if (result.data && result.data.success === false) {
      return {
        isOk: false,
        message: result.data.message,
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function resetPasswordService(data) {
  try {
    const result = await getApiClient().post('reset-password', data)

    if (result.data && result.data.success) {
      return {
        isOk: true,
      }
    } else {
      return {
        isOk: false,
        message: 'Desila se greška.',
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function askForMailVerificationService(data) {
  try {
    const result = await getApiClient(data.accessToken).post(
      'ask-mail-verification',
      { email: data.email }
    )
    if (result.status === 200) {
      return {
        isOk: true,
      }
    } else {
      return {
        isOk: false,
        message: 'Desila se greška!',
      }
    }
  } catch (error) {
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getFreeDeliveryUsers() {
  try {
    const result = await getApiClient().get('korisnici/free-delivery')
    if (result.status === 200 && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    return {
      isOk: false,
    }
  }
}

export {
  loginService,
  updateUserService,
  registerService,
  verifyEmailService,
  sendEmailForForgotPasswordService,
  resetPasswordService,
  askForMailVerificationService,
  getFreeDeliveryUsers,
  getUserService
}
