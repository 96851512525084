import React, {  useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateActiveCategoryIndex } from "../store/Actions/Products"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"

export default function CategoriesRow(props) {
  const dispatch = useDispatch()

  const {isSearchMode} = props

  const {categories, activeCategoryIndex} = useSelector(state => state.products)

  const [isCategoriesMenuVisible, setIsCategoriesMenuVisible] = useState(false)

  return (
    <div className="kategorijeRow">
        {!isSearchMode && (
          <button
            className="button greenButton categoryDropdown"
            onClick={() =>
             setIsCategoriesMenuVisible(!isCategoriesMenuVisible)
            }
          >
            {categories[activeCategoryIndex]?.Naziv}
            <span className="carretIcon">
              <FontAwesomeIcon icon={faCaretDown} />
            </span>
          </button>
        )}
        {isCategoriesMenuVisible && (
          <div className="categoriesList">
            {categories.map((category, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setIsCategoriesMenuVisible(false)
                    dispatch(updateActiveCategoryIndex(index))
                  }}
                >
                  {category.Naziv}
                </div>
              )
            })}
          </div>
        )}
      </div>
  )
}
