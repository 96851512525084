import * as actionTypes from './ActionTypes'
import { getDeliveryPriceService, getMinimumOrderPriceService } from '../../services/SharedData'

export const getDeliveryPrice = () => async (dispatch) => {
  const result = await getDeliveryPriceService()
  if (result.isOk) {
    dispatch(getDeliveryPriceSuccess(result.data))
  }
}

export const getDeliveryPriceSuccess = (data) => {
  return {
    type: actionTypes.GET_DELIVERY_PRICE_SUCCESS,
    payload: {
      data,
    },
  }
}

export const getMinimumOrderPrice = () => async (dispatch) => {
  const result = await getMinimumOrderPriceService()
  if(result.isOk) {
    dispatch(getMinimumOrderPriceSuccess(result.data))
  }
}

export const getMinimumOrderPriceSuccess = (data) => {
  return {
    type: actionTypes.GET_MINIMUM_ORDER_PRICE_SUCCESS,
    payload: {
      data
    }
  }
}
