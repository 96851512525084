import React from 'react'
import { correctEmail } from '../utils/formValidation'
import {
  HeaderBackground,
  Navbar,
  ErrorNotification,
  FullScreenLoader,
  SuccessNotification,
} from '../components'
import { sendEmailForForgotPasswordService } from '../services/User'

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: null,
      isProcessing: false,
      success: null,
    }

    this.sendLink = this.sendLink.bind(this)
  }

  async sendLink() {
    this.setState({ isProcessing: true })
    const { email } = this.state
    if (!correctEmail(email)) {
      this.setState({ error: 'Email nije validan!', isProcessing: false })
      return
    }

    const result = await sendEmailForForgotPasswordService({ email })
    if (result.isOk) {
      this.setState({ success: true })
    } else {
      this.setState({ error: result.message })
    }

    this.setState({ isProcessing: false })
  }

  render() {
    return (
      <>
        <Navbar />
        <HeaderBackground title="" />
        <div className="section" id="_forgotPasswordPage">
          <div className="container">
            <div className="forgotPasswordForm">
              {this.state.error && (
                <ErrorNotification errorMessage={this.state.error} />
              )}
              {this.state.success && (
                <SuccessNotification successMessage="Provjerite vaš Email kako biste resetovali lozinku." />
              )}
              {!this.state.success && (
                <>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className="input"
                        type="email"
                        placeholder="Unesite svoj email..."
                        value={this.state.email}
                        onChange={(event) =>
                          this.setState({ email: event.target.value })
                        }
                        onFocus={() => this.setState({ error: null })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <span className="icon is-small is-right">
                        <i className="fas fa-exclamation-triangle"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <button
                      className="button greenButton is-fullwidth mt-3"
                      onClick={() => this.sendLink()}
                    >
                      Pošalji link
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    )
  }
}
