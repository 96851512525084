import { getApiClient } from './AxiosService'

async function getAdminSettings() {
  try {
    const result = await getApiClient().get(`/admin-settings`)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    // console.log('home error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

export { getAdminSettings }
