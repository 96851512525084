import { getApiClient } from './AxiosService'

async function getDeliveryPriceService() {
  try {
    const result = await getApiClient().get(`/delivery-price`)
    if (result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data,
      }
    }
  } catch (error) {
    // console.log('home error: ', error)
    return {
      isOk: false,
      message: error.message,
    }
  }
}

async function getMinimumOrderPriceService() {
  try {
    const result = await getApiClient().get(`/minimum-order-price`)
    if(result.status === 200 && result.data && result.data.code === 200) {
      return {
        isOk: true,
        data: result.data.data
      }
    }
  } catch (error) {
    return {
      isOk: false
    }
  }
}

export { getDeliveryPriceService, getMinimumOrderPriceService }
