import React from 'react'
import {
  HeaderBackground,
  Navbar,
  ErrorNotification,
  FullScreenLoader,
  SuccessNotification,
} from '../components'
import { hasValue, same } from '../utils/formValidation'
import { resetPasswordService } from '../services/User'
import { connect } from 'react-redux'

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      confirmPassword: '',
      isProcessing: false,
      error: null,
      success: null,
    }

    this.resetPassword = this.resetPassword.bind(this)
  }

  async resetPassword() {
    this.setState({ isProcessing: true })
    const query = new URLSearchParams(this.props.location.search)
    const email = decodeURIComponent(query.get('email'))
    const emailKey = query.get('emailKey')

    const password = this.state.password.trim()
    const confirmPassword = this.state.confirmPassword.trim()

    if (!hasValue(password) || !hasValue(confirmPassword)) {
      this.setState({
        isProcessing: false,
        error: 'Polja ne smiju biti prazna.',
      })
      return
    }

    if (!same(password, confirmPassword)) {
      this.setState({ isProcessing: false, error: 'Lozinke se ne podudaraju.' })
      return
    }

    const result = await resetPasswordService({
      email,
      emailKey,
      newPassword: password,
    })
    if (result.isOk) {
      this.setState({ success: true, isProcessing: false })
    } else {
      this.setState({ error: result.message, isProcessing: false })
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <HeaderBackground title="Reset lozinke" />
        <div className="section" id="_resetPasswordPage">
          <div className="container">
            <div className="resetPasswordForm">
              {this.state.error && (
                <ErrorNotification errorMessage={this.state.error} />
              )}
              {this.state.success && (
                <SuccessNotification successMessage="Uspješno ste resetovali lozinku. Sada se možete prijaviti na vaš račun." />
              )}
              {!this.state.success && (
                <>
                  <div className="field">
                    <label className="label">Lozinka</label>
                    <div className="control has-icons-left ">
                      <input
                        className="input"
                        type="password"
                        placeholder="Unesite novu lozinku..."
                        value={this.state.password}
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        onFocus={() => this.setState({ error: null })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <label className="label">Ponovi lozinku</label>
                    <div className="control has-icons-left ">
                      <input
                        className="input"
                        type="password"
                        placeholder="Ponovite novu lozinku..."
                        value={this.state.confirmPassword}
                        onChange={(event) =>
                          this.setState({ confirmPassword: event.target.value })
                        }
                        onFocus={() => this.setState({ error: null })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                    </div>
                  </div>

                  <div className="field">
                    <button
                      className="button greenButton is-fullwidth mt-4"
                      onClick={() => this.resetPassword()}
                    >
                      Resetuj lozinku
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  accessToken: state.authentication.token?.access_token,
})

const mapDispatchToProps = {
  //
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
