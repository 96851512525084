import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Navbar,
  HeaderBackground,
  FullScreenLoader,
  SuccessNotification,
  ErrorNotification,
  UnderlinedButton,
} from "../components";
import {
  getTotalPrice,
  getTotalPriceNumber,
} from "../store/Selectors/Products";
import { sendOrderService, getLastMonthExpenseData } from "../services/Order";
import { removeProductsFromCart } from "../store/Actions/Products";
import { getAdminSettings } from "../services/AdminSettings";
import {
  getMinimunOrder,
} from "../store/Selectors/Shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { monthlyDiscountMessage } from "../constants"
import { hasValue } from "../utils/formValidation"

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      napomena: "",
      donacija: '',
      isProcessing: false,
      successMessage: null, // is set when order is sent
      errorMessage: null,
      isButtonDisabled: false,
      infoVisible: false,
      adminSettings: null,
      deliveryFreeUsers: [],
      lastMonthExpenseData: null,
      monthlyDiscountMessageVisible: false,
    };

    this.getBrojStavki = this.getBrojStavki.bind(this);
    this.sendOrder = this.sendOrder.bind(this);
    this.getLastMonthExpenseData = this.getLastMonthExpenseData.bind(this);
    this.getCijenaDostave = this.getCijenaDostave.bind(this);
  }

  componentDidMount() {
    this.getAdminSettingsData();
    this.getLastMonthExpenseData();
  }

  async getLastMonthExpenseData() {
    const data = { accessToken: this.props.token?.access_token };
    const response = await getLastMonthExpenseData(data);
    if (response.isOk) {
      this.setState({ lastMonthExpenseData: response.data });
    }
  }

  async getAdminSettingsData() {
    const result = await getAdminSettings();
    if (result.isOk) {
      this.setState({ adminSettings: result.data });
    }
  }

  getBrojStavki() {
    const brojProizvoda = this.props.productsInCart?.length;
    if (brojProizvoda === 1) return `${brojProizvoda} stavka`;
    else if (brojProizvoda > 1 && brojProizvoda < 5)
      return `${brojProizvoda} stavke`;
    else return `${brojProizvoda} stavki`;
  }

  async sendOrder() {
    if (this.props.totalPrice < this.props.minimumOrder) {
      alert(
        `Minimalna cijena narudžbe je ${this.props.minimumOrder.toFixed(2)} KM.`
      );
      return;
    }

    if (this.props.user.grad === null || this.props.user.grad === "") {
      alert(
        'Grad nije odabran za vaš korisnički račun. Molimo dodajte ga u opciji "Moj račun"'
      );
      return;
    }

    let donacija = null
    if(hasValue(this.state.donacija)) {
      if(isNaN(this.state.donacija) || Number(this.state.donacija) < 0) {
        alert('Vrijednost donacije nije ispravna.');
        return;
      } else {
        donacija = Number(Number(this.state.donacija).toFixed(2))
      }
    }

    this.setState({ isButtonDisabled: true, isProcessing: true });
    const narudzba = {
      cijena_narudzbe: this.props.totalPrice,
      grad: this.props.user.grad,
      napomena: this.state.napomena,
      donacija: donacija !== null && donacija > 0 ? donacija : null
    };

    const proizvodi = this.props.productsInCart.map((product) => {
      return {
        proizvodID: product.proizvodID,
        kolicina: product.kolicina,
      };
    });

    const data = {
      narudzba,
      proizvodi,
      accessToken: this.props.token?.access_token,
    };
    const result = await sendOrderService(data);
    if (result.isOk) {
      this.setState({
        isProcessing: false,
        isButtonDisabled: false,
        // successMessage: "Uspješno ste poslali narudžbu!",
      });
      this.props.removeProductsFromCart();
      this.props.history.push("/narudzbe");
    } else {
      this.setState({
        isProcessing: false,
        isButtonDisabled: false,
        errorMessage: result.message,
      });
    }
  }

  getTotalPrice(orderPrice, delivery, lastMonthExpenseData) {
    let total = orderPrice;
    if (lastMonthExpenseData?.monthly_discount) {
      total = total - total * (lastMonthExpenseData.monthly_discount / 100);
    }

    total = total + delivery;

    return total;
  }

  getCijenaDostave() {
    const { nasiGradovi, nasiGradoviCijena, ostaliGradoviCijena, minNarudzbaZaBesplatnuDostavuZaNaseGradove, minNarudzbaZaBesplatnuDostavuZaOstaleGradove } = this.props.deliveryData;
    const { grad } = this.props.user;
    
    let dostava = 0;
  
    if (grad && nasiGradovi) {
      const isNasGrad = nasiGradovi.includes(grad);
      dostava = isNasGrad ? nasiGradoviCijena : ostaliGradoviCijena;
  
      const minNarudzbaZaBesplatnuDostavu = isNasGrad ? minNarudzbaZaBesplatnuDostavuZaNaseGradove : minNarudzbaZaBesplatnuDostavuZaOstaleGradove;
  
      if (this.props.orderPrice >= minNarudzbaZaBesplatnuDostavu) {
        dostava = 0;
      }
    }
  
    return dostava;
  }
  

  render() {
    const napomena = this.state.adminSettings?.config?.napomena[0]?.Vrijednost;
    const blokirajNarudzbe =
      this.state.adminSettings?.config?.blokirajNarudzbe[0]?.Vrijednost;


    const cijenaDostave = this.getCijenaDostave();

    const prikaziDonacije = this.state.adminSettings?.config?.prikaziDonacije[0]?.Vrijednost

    return (
      <>
        <Navbar />
        <HeaderBackground title="" />

        <div className="section" id="checkout">
          <div className="container">
            {!this.state.successMessage && (
              <div className="checkoutForma">
                {this.state.errorMessage && (
                  <div data-aos="fade-down">
                    <ErrorNotification errorMessage={this.state.errorMessage} />
                  </div>
                )}
                <p className="brojStavki">{`${this.getBrojStavki()} / ${
                  this.props.totalPrice
                } KM`}</p>

                {this.props.user?.grad && (
                  <div className="checkout-data-div">
                    {this.state.lastMonthExpenseData ? (
                      <>
                        <p>
                          {`Mjesečni popust: `}
                          {`${
                            this.state.lastMonthExpenseData?.monthly_discount ??
                            `0`
                          }%`}{" "}
                          <span
                            onClick={() => {
                              this.setState({
                                monthlyDiscountMessageVisible: true,
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              className="info-icon"
                              icon={faInfoCircle}
                            />
                          </span>{" "}
                          <br />{" "}
                          <span className="last-month-expense-info">{`(Troškovi narudžbe za prošli mjesec: ${this.state.lastMonthExpenseData?.monthly_expense?.toFixed(
                            2
                          )} KM)`}</span>
                        </p>
                      </>
                    ) : null}
                    <p>{`Cijena dostave: ${cijenaDostave.toFixed(2)} KM`}</p>
                    <p>
                      <b>
                        {`Total: ${this.getTotalPrice(
                          this.props.orderPrice,
                          cijenaDostave,
                          this.state.lastMonthExpenseData
                        ).toFixed(2)} KM`}
                      </b>
                    </p>

                    {this.props.productsInCart.length === 0 && (
                      <div className="mt-2 is-flex is-justify-content-center">
                        <UnderlinedButton
                          txt="Započni narudžbu"
                          linkTo="/shop"
                          isGreen={true}
                          isPurple={true}
                        />
                      </div>
                    )}
                  </div>
                )}

                {this.props.token?.access_token ? (
                  <>
                    <div className="korisnikData">
                      <p>Dostaviti za: </p>
                      <p>{`${this.props.user.ime} ${this.props.user.prezime}`}</p>
                      <p>{this.props.user.adresa}</p>
                      <p>{this.props.user.grad}</p>
                      <p>{this.props.user.telefon}</p>
                      <p>
                        Korisničke podatke možete promijeniti{" "}
                        <Link to="/korisnik">ovdje</Link>
                      </p>
                    </div>

                    <textarea
                      className="textarea"
                      placeholder="Napomena..."
                      onChange={(event) =>
                        this.setState({ napomena: event.target.value })
                      }
                    ></textarea>

                    {prikaziDonacije === "DA" ? <div className="field mt-4 donacija">
                      <div className="control">
                        <label className="label">Pomozi.ba</label>
                        <p>{`Imamo samo ono što damo. Ovo je Vaš put da zajedno doniramo zdrave namirnice ljudima koji to sebi ne mogu priuštiti. Na sedmičnoj bazi ćemo slati donacije i sve objavljivati na društvenim mrežama. Proizvodi koje doniramo će biti obračunati po minimalnim cijenama.`}</p>
                        <input
                          className="input mt-4"
                          placeholder="Donacija..."
                          type="number"
                          value={this.state.donacija}
                          onChange={(event) => {
                            this.setState({ donacija: event.target.value })
                          }
                          }
                      />
                      </div>
                    </div> : null}

                    {napomena && (
                      <p className="has-text-weight-bold mt-4">{napomena}</p>
                    )}

                    {blokirajNarudzbe !== "DA" && (
                      <button
                        className="button greenButton is-fullwidth"
                        onClick={this.sendOrder}
                        disabled={this.state.isButtonDisabled}
                      >
                        Naručite
                      </button>
                    )}
                  </>
                ) : (
                  <p>
                    Morate biti prijavljeni kako biste poslali narudžbu.
                    <br /> Prijavite se <Link to="/korisnik">ovdje</Link>
                  </p>
                )}
              </div>
            )}

            {this.state.successMessage && (
              <div className="orderConfirmation">
                <div data-aos="fade-down">
                  <SuccessNotification
                    successMessage={this.state.successMessage}
                  />
                </div>
                <p className="brojStavki">{`${this.getBrojStavki()} / ${
                  this.props.totalPrice
                } KM`}</p>
                <div className="korisnikData">
                  <p>{`${this.props.user.ime} ${this.props.user.prezime}`}</p>
                  <p>{this.props.user.adresa}</p>
                  <p>{this.props.user.grad}</p>
                  <p>{this.props.user.telefon}</p>
                </div>

                <p>
                  Detalje vaše narudžbe možete pogledati{" "}
                  <Link to="/narudzbe">ovdje</Link>.
                </p>
              </div>
            )}
          </div>

          <div class={`modal discount-modal ${this.state.monthlyDiscountMessageVisible ? 'is-active' : ''}`}>
            <div class="modal-background"></div>
            <div class="modal-content">
              <h2>Mjesečni popust</h2>
              <p>{monthlyDiscountMessage}</p>
            </div>
            <button class="modal-close is-large" onClick={() => this.setState({ monthlyDiscountMessageVisible: false })} aria-label="close"></button>
        </div>
        </div>
        {this.state.isProcessing && <FullScreenLoader note="Procesiranje..." />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token,
  productsInCart: state.products.productsInCart,
  totalPrice: getTotalPrice(state),
  orderPrice: getTotalPriceNumber(state),
  user: state.user.userData,
  minimumOrder: getMinimunOrder(state),
  deliveryData: state.shared.deliveryPrice
});

const mapDispatchToProps = {
  removeProductsFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
