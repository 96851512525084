import * as actionTypes from "./ActionTypes";

export const loginSuccess = (token) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {
      token,
    },
  };
};

export const updateToken = (token) => {
  return {
    type: actionTypes.UPDATE_TOKEN,
    payload: {
      token,
    },
  };
};

export const deleteToken = () => {
  return {
    type: actionTypes.DELETE_TOKEN,
  };
};
