export const getCijenaDostaveZaGrad = (state) => {
  const { grad } = state.user.userData
  const { nasiGradovi, nasiGradoviCijena, ostaliGradoviCijena } =
    state.shared.deliveryPrice

  if (grad && nasiGradovi) {
    const isNasGrad = nasiGradovi.some((g) => g === grad)
    if (isNasGrad) {
      return nasiGradoviCijena
    } else {
      return ostaliGradoviCijena
    }
  }
}

export const getMinimunOrder = (state) => {
  const { grad } = state.user.userData
  const { nasiGradovi } = state.shared.deliveryPrice

  const minimumOrder = state.shared.minimumOrder

  if(grad && nasiGradovi) {
    const isNasGrad = nasiGradovi.some(g => g === grad)
    if(isNasGrad) {
      return minimumOrder.nasiGradovi
    } else {
      return minimumOrder.ostaliGradovi
    }
  }
}
