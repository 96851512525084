import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import Home from './pages/Home'
import Ponuda from './pages/Ponuda'
import MojaKorpa from './pages/MojaKorpa'
import Korisnik from './pages/Korisnik'
import Checkout from './pages/Checkout'
import Narudzbe from './pages/Narudzbe'
import Registracija from './pages/Registracija'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import Login from './pages/Login'
import EmailVerification from './pages/EmailVerification'
import Favoriti from './pages/Favoriti'

import My404Component from './components/My404Component'

import { getProducts, getCategories } from './store/Actions/Products'
import { getDeliveryPrice, getMinimumOrderPrice } from './store/Actions/Shared'
import { getUser } from './store/Actions/User'

import AOS from 'aos'
import 'aos/dist/aos.css'
AOS.init({ once: true })

class App extends React.Component {
  componentDidMount() {
    this.props.getProductsAction()
    this.props.getCategoriesAction()
    this.props.getDeliveryPrice()
    this.props.getMinimumOrderPrice()

    if(this.props.token?.access_token) {
      this.props.getUser(this.props.token.access_token)
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />

          {/* /ponuda -------> /shop */}
          <Route path="/shop" component={Ponuda} />

          {/* /korpa --------> /cart */}
          <Route path="/cart" component={MojaKorpa} />
          <Route path="/login" component={Login} />
          <Route path="/korisnik" component={Korisnik} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/narudzbe" component={Narudzbe} />
          <Route path="/registracija" component={Registracija} />
          <Route path="/email-verification" component={EmailVerification} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/favoriti" component={Favoriti} />
          <Route component={My404Component} />
        </Switch>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token,
})

const mapDispatchToProps = {
  getProductsAction: getProducts,
  getCategoriesAction: getCategories,
  getDeliveryPrice: getDeliveryPrice,
  getMinimumOrderPrice: getMinimumOrderPrice,
  getUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
