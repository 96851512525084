import React from "react";
import Loader from "react-loader-spinner";

export default function FullScreenLoader(props) {
  return (
    <div className="modal is-active fullScreenLoader">
      <div className="modal-background"></div>
      <div className="modal-content">
        <Loader type="TailSpin" color="#00BFFF" height={50} width={50} />
        <p>{props.note}</p>
      </div>
    </div>
  );
}
