import React from 'react'
import { Link } from 'react-router-dom'
import NavLogo from '../images/nav_logo.png'
import UserIcon from '../images/user_icon.png'
import HeartIcon from '../images/heart_icon.png'
import CartIcon from '../images/cart_icon.png'
import { useSelector } from 'react-redux'

export default function Navbar() {
  const [isActive, setisActive] = React.useState(false)
  const numberOfProductsInCart = useSelector(
    (state) => state.products.productsInCart?.length
  )
  const numberOfFavouriteProducts = useSelector(
    (state) => state.products.favouriteProducts?.length
  )

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src={NavLogo} alt="Logo" className="navLogo" />
        </a>

        <p
          onClick={() => {
            setisActive(!isActive)
          }}
          role="button"
          className={`navbar-burger ${isActive ? 'is-active' : ''}`}
          data-target="navMenu"
          aria-label="menu"
          aria-expanded="false"
        >
          <p className="is-hidden-desktop is-hidden-fullhd">Meni</p>
        </p>
      </div>

      <div
        id="navbarMenu"
        className={`navbar-menu ${isActive ? 'is-active' : ''}`}
      >
        <div className="navbar-start">
          <Link to="/" className="navbar-item txtLink">
            Početna
          </Link>
          <Link to="/shop" className="navbar-item txtLink">
            Ponuda
          </Link>
          <Link to="/narudzbe" className="navbar-item txtLink">
            Narudžbe
          </Link>
        </div>

        <div className="navbar-end">
          <Link to="/korisnik" className="navbar-item txtLink">
            <img
              src={UserIcon}
              className="custom-icon"
              alt="Korpa dravlja - Korisnik"
            />
            <span className="spanText account is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
              Moj Račun
            </span>
          </Link>
          <Link to="/favoriti" className="navbar-item txtLink">
            <img
              src={HeartIcon}
              className="custom-icon"
              alt="Korpa zdravlja - Favoriti page"
            />
            <span className="numberOfProducts">
              {numberOfFavouriteProducts}
            </span>
            <span className="spanText is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
              Omiljeni proizvodi
            </span>
          </Link>
          <Link to="/cart" className="navbar-item txtLink">
            <img
              src={CartIcon}
              className="custom-icon"
              alt="Korpa zdravlja - Cart"
            />
            <span className="numberOfProducts">{numberOfProductsInCart}</span>
            <span className="spanText is-hidden-desktop is-hidden-widescreen is-hidden-fullhd">
              Moja korpa
            </span>
          </Link>
        </div>
      </div>
    </nav>
  )
}
