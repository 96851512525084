import React from "react"

export default function SuccessMessage(props) {
  return (
    <div
      className="successMessage"
      style={{ ...props.customStyle }}
      data-aos="fade-up"
    >
      <p>{props.message}</p>
    </div>
  )
}
