import React from 'react'
import {
  HeaderBackground,
  Navbar,
  CategoriesRow,
  CartPeak,
  ProductModal,
  Product,
} from '../components'
import { connect } from 'react-redux'
import {
  getProductsByCategory,
  getProductsGroupedByCategory,
  getTotalPrice,
} from '../store/Selectors/Products'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons'
import {
  faTimesCircle,
} from '@fortawesome/free-regular-svg-icons'
import { addProductToCart, removeProduct } from '../store/Actions/Products'
import { filterData } from '../utils/dataFilter'

class Ponuda extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isCartPeakVisible: false,
      products: [ ...props.products ],
      filteredProducts: [],
      vrijednostPretrage: '',
      isSearchMode: false,
      isModalProductInCart: false,
      isProductModalVisible: false,
    }

    this.goToProduct = this.goToProduct.bind(this)
    this.closeCartPeak = this.closeCartPeak.bind(this)
    this.goToCart = this.goToCart.bind(this)
    this.handleSearchOnBlur = this.handleSearchOnBlur.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    if (this.props.activeCategoryIndex !== prevProps.activeCategoryIndex) {
      this.setState({ products: this.props.products })
    }
  }

  goToProduct(product, isModalProductInCart, notAvailable) {
    this.setState({
      modalProduct: product,
      isModalProductInCart,
      isProductModalVisible: true,
      productNotAvailable: notAvailable,
    })
  }

  closeCartPeak() {
    this.setState({ isCartPeakVisible: false })
  }

  goToCart() {
    this.closeCartPeak()
    this.props.history.push('/cart')
  }

  handleSearchResult(searchInput) {
    let vrijednostPretrage = searchInput.trim()
    this.setState({ vrijednostPretrage })
    if (vrijednostPretrage === '') {
      this.setState({ filteredProducts: [] })
      return
    }
    let filteredProducts = filterData(
      vrijednostPretrage,
      this.props.allProducts
    )
    this.setState({ filteredProducts })
  }

  handleSearchOnBlur() {
    const { vrijednostPretrage } = this.state
    if (vrijednostPretrage === null || vrijednostPretrage.trim() === '')
      this.setState({ isSearchMode: false })
  }

  render() {
    const products = this.state.isSearchMode
      ? this.state.filteredProducts
      : this.props.products
    return (
      <>
        <Navbar />
        <HeaderBackground title="Shop" />

        <div className="section" id="ponuda_proizvodi">
          <div className="container">
            <div className="categorySearchWrapper">
              <CategoriesRow isSearchMode={this.state.isSearchMode} />
              <div className="field">
                <div className="control has-icons-left has-icons-right">
                  <input
                    className="input"
                    type="text"
                    placeholder="Pretraga..."
                    value={this.state.vrijednostPretrage}
                    onFocus={() => this.setState({ isSearchMode: true })}
                    onBlur={() => this.handleSearchOnBlur()}
                    onChange={(event) =>
                      this.handleSearchResult(event.target.value)
                    }
                  />
                  <span
                    onClick={() =>
                      this.setState({
                        isSearchMode: false,
                        vrijednostPretrage: '',
                        filteredProducts: [],
                      })
                    }
                    className="icon is-small is-right closeIcon"
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </span>
                </div>
              </div>
            </div>

            {this.state.isSearchMode &&
              (this.state.vrijednostPretrage === '' ||
                this.state.vrijednostPretrage === null) && (
                <p className="has-text-centered">
                  Unesite vrijednost za pretragu...
                </p>
              )}

            {this.state.isSearchMode &&
              this.state.vrijednostPretrage !== '' &&
              this.state.vrijednostPretrage !== null &&
              products.length === 0 && (
                <p className="has-text-centered">
                  Nema rezultata pretrage za "{this.state.vrijednostPretrage}"
                </p>
              )}

            <div className="columns is-multiline">
              {products.map((product) => {
                const isProductInCart = this.props.productsInCart.find(
                  (x) => x.proizvodID === product.proizvodID
                )
                return (
                  <div
                    className="column is-4 is-3-desktop is-2-widescreen"
                    key={product.proizvodID}
                  >
                    <Product
                      key={product.proizvodID}
                      product={product}
                      handleOnClick={this.goToProduct}
                      isProductInCart={isProductInCart}
                      showSuccessMessage={true}
                    />
                  </div>
                )
              })}
            </div>

            {/** Following block displays products from all categories  */}
            {this.props.categories[this.props.activeCategoryIndex]?.Naziv === 'Svi proizvodi' ? <>
              {this.props.groupedProducts?.map(groupedProducts => {
                return (
                  <>
                  <div className='category-title'>{groupedProducts?.name}</div>
                  <div className="columns is-multiline">
                  {groupedProducts?.products?.map((product) => {
                    const isProductInCart = this.props.productsInCart.find(
                      (x) => x.proizvodID === product.proizvodID
                    )
                    return (
                      <div
                        className="column is-4 is-3-desktop is-2-widescreen"
                        key={product.proizvodID}
                      >
                        <Product
                          key={product.proizvodID}
                          product={product}
                          handleOnClick={this.goToProduct}
                          isProductInCart={isProductInCart}
                          showSuccessMessage={true}
                        />
                      </div>
                    )
                  })}
            </div>
                </>
                )
              })}
            </> : null}
          </div>

          {!this.state.isCartPeakVisible && (
            <div className="korpaPeakIcon">
              <span
                className="leftChevronIcon"
                onClick={() => this.setState({ isCartPeakVisible: true })}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
            </div>
          )}
        </div>

        {this.state.isCartPeakVisible && (
          <CartPeak
            productsInCart={this.props.productsInCart}
            totalPrice={this.props.totalPrice}
            closeCartPeak={this.closeCartPeak}
            goToCart={this.goToCart}
            removeProductFromCart={this.props.removeProductFromCart}
          />
        )}

        {this.state.isProductModalVisible && (
          <ProductModal
            product={this.state.modalProduct}
            isModalProductInCart={this.state.isModalProductInCart}
            addProductToCart={this.props.addProductToCart}
            removeProductFromCart={this.props.removeProductFromCart}
            closeModal={() => this.setState({ isProductModalVisible: false })}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  allProducts: state.products.list,
  products: getProductsByCategory(state),
  groupedProducts: getProductsGroupedByCategory(state),
  totalPrice: getTotalPrice(state),
  productsInCart: state.products.productsInCart,
  categories: state.products.categories,
  activeCategoryIndex: state.products.activeCategoryIndex,
})

const mapDispatchToProps = {
  addProductToCart: addProductToCart,
  removeProductFromCart: removeProduct,
}

export default connect(mapStateToProps, mapDispatchToProps)(Ponuda)
