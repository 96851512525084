import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

export default function ErrorNotification(props) {
  return (
    <div className="errorNotification" style={{ ...props.customstyle }}>
      <span>
        <FontAwesomeIcon icon={faExclamationCircle} />
      </span>
      <p>{props.errorMessage}</p>
    </div>
  )
}
