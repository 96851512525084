export const hasValue = (value) => {
  return value != null && value.trim() !== ""
}
export const correctEmail = (email) => {
  var re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const hasMinLength = (value, length) => {
  return hasValue(value) && value.trim().length >= length
}

export const same = (value1, value2) => {
  return value1 === value2
}
