import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, HeaderBackground } from '../components'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { updateUser, updateUserSuccess } from '../store/Actions/User'
import { verifyEmailService } from '../services/User'

class EmailVerfification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailSuccessfullyVerified: false,
    }
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    const token = query.get('t')
    if (token != null && token.trim() !== '') {
      const result = await verifyEmailService({ access_token: token })
      if (result.isOk) {
        this.setState({ emailSuccessfullyVerified: true })

        if (this.props.accessToken) {
          this.props.updateUserSuccess(result.data)
        }
      }
    }
  }

  render() {
    return (
      <>
        <Navbar />
        <HeaderBackground title="Email verifikacija" />
        {this.state.emailSuccessfullyVerified && (
          <div className="iconDiv mt-6">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="checkedCircleIcon mt-6"
            />
            <p className="mt-4 is-size-5 mb-2">Vaš Email je verifikovan.</p>
            <p className="mt-6">
              {`Nastavite kupovinu `}
              <Link to="/checkout">ovdje</Link>
            </p>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  accessToken: state.authentication.token?.access_token,
})

const mapDispatchToProps = {
  updateUser,
  updateUserSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerfification)
