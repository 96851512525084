import React from 'react'
import Images from '../images'

export default function Footer() {
  return (
    <footer id="w_footer" className="section">
      <div className="container level">
        <div className="level-item has-text-centered">
          <div>
            <img
              src={Images.MapPin}
              className="footer_icon"
              alt="Kopra zdravlja - Mapa"
            />
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/dir/Current+Location/Korpa+Zdravlja"
              >
                Donje Moštre 71305 Visoko, BiH
              </a>
            </p>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <img
              src={Images.Phone}
              className="footer_icon"
              alt="Kopra zdravlja - Telefon"
            />
            <p>
              <a href="tel:+38762224146">062-573-375</a>
            </p>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <img
              src={Images.FbLogo}
              className="footer_icon"
              alt="Kopra zdravlja - Facebook"
            />
            <p>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/korpazdravlja/">
                Facebook
              </a>
            </p>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <img
              src={Images.InstagramLogo}
              className="footer_icon"
              alt="Kopra zdravlja - Instagram"
            />
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/korpa.zdravlja/"
              >
                Instagram
              </a>
            </p>
          </div>
        </div>

        <div className="level-item has-text-centered">
          <div>
            <img
              src={Images.Email}
              className="footer_icon"
              alt="Kopra zdravlja - Email"
            />
            <p>
              <a href="mailto:info@korpazdravlja.ba">info@korpazdravlja.ba</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
