import * as actionTypes from './ActionTypes'
import {
  loginService,
  updateUserService,
  registerService,
  getUserService
} from '../../services/User'
import { updateToken } from './Authentication'
import { setFavourites } from './Products'

export const login =
  ({ username, password }) =>
  async (dispatch) => {
    const result = await loginService(username, password)
    // console.log('result: ', result)
    if (result.isOk) {
      dispatch(loginSuccess(result.data))
      dispatch(updateToken(result.token))
      dispatch(setFavourites(result.favourites))
    } else {
      dispatch(setLoginError(result.message))
    }
  }

export const loginSuccess = (userData) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: {
      userData,
    },
  }
}

export const setLoginError = (loginError) => {
  return {
    type: actionTypes.SET_LOGIN_ERROR,
    payload: {
      loginError,
    },
  }
}

export const register = (data) => async (dispatch) => {
  const result = await registerService(data)
  if (result.isOk) {
    // console.log('reg result: ', result)
    dispatch(registerSuccess(result.data))
    dispatch(updateToken(result.token))
  } else {
    dispatch(setRegisterError(result.message))
  }
}

export const registerSuccess = (userData) => {
  return {
    type: actionTypes.REGISTER_SUCCESS,
    payload: {
      userData,
    },
  }
}

export const setRegisterError = (registerError) => {
  return {
    type: actionTypes.SET_REGISTER_ERROR,
    payload: {
      registerError,
    },
  }
}

export const logout = () => {
  return {
    type: actionTypes.LOGOUT,
  }
}

export const getUser = (data) => async (dispatch) => {
  const result = await getUserService(data)
  if(result.isOk) {
    dispatch(getUserSuccess(result.data))
  }
}

export const updateUser = (userData) => async (dispatch) => {
  const result = await updateUserService(userData)
  if (result.isOk) {
    dispatch(updateUserSuccess(result.data))
  } else {
    dispatch(setUserUpdateError(result.message))
  }
}

export const setUserUpdateError = (error) => {
  return {
    type: actionTypes.SET_USER_UPDATE_ERROR,
    payload: {
      error,
    },
  }
}

export const updateUserSuccess = (userData) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: {
      userData,
    },
  }
}

export const getUserSuccess = (userData) => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    payload: {
      userData
    }
  }
}
