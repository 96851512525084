import React from "react"
import Loader from "react-loader-spinner"

export default function Spinner(props) {
  return (
    <div className="spinnerWrapper" style={{ ...props.customStyle }}>
      <Loader type="TailSpin" color="#00BFFF" height={40} width={40} />
      <p>{props.note}</p>
    </div>
  )
}
