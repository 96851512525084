import React from 'react'

export default function ErrorMessage(props) {
  return (
    <div
      className="errorMessage"
      style={{ ...props.customStyle }}
      data-aos="fade-up"
    >
      <p>{props.message}</p>
    </div>
  )
}
