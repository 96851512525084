import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, HeaderBackground } from '../components'

export default function My404Component() {
  return (
    <>
      <Navbar />
      <HeaderBackground title="Greška 404" />
      <p className="has-text-centered mt-6">
        {`Stranica nije pronađena. Vratite se na `} <Link to="/">Početnu</Link>
      </p>
    </>
  )
}
