export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const SET_REGISTER_ERROR = 'SET_REGISTER_ERROR'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const UPDATE_ACTIVE_CATEGORY_INDEX = 'UPDATE_ACTIVE_CATEGORY_INDEX'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY'
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY'
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const SET_USER_UPDATE_ERROR = 'SET_USER_UPDATE_ERROR'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const REMOVE_PRODUCTS_FROM_CART = 'REMOVE_PRODUCTS_FROM_CART'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'
export const REMOVE_UNAVAILABLE_PRODUCTS = 'REMOVE_UNAVAILABLE_PRODUCTS'
export const ADD_PRODUCT_TO_FAVOURITE = 'ADD_PRODUCT_TO_FAVOURITE'
export const REMOVE_PRODUCT_FROM_FAVOURITE = 'REMOVE_PRODUCT_FROM_FAVOURITE'
export const SET_FAVOURITES = 'SET_FAVOURITES'
export const CLEAR_FAVOURITE_PRODUCTS = 'CLEAR_FAVOURITE_PRODUCTS'
export const GET_NOVA_PONUDA_SUCCESS = 'GET_NOVA_PONUDA_SUCCESS'
export const GET_POPULARNI_PROIZVODI_SUCCESS = 'GET_POPULARNI_PROIZVODI_SUCCESS'
export const GET_DELIVERY_PRICE = 'GET_DELIVERY_PRICE'
export const GET_DELIVERY_PRICE_SUCCESS = 'GET_DELIVERY_PRICE_SUCCESS'
export const GET_MINIMUM_ORDER_PRICE = 'GET_MINIMUM_ORDER_PRICE'
export const GET_MINIMUM_ORDER_PRICE_SUCCESS = 'GET_MINIMUM_ORDER_PRICE_SUCCESS'
